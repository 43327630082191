import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from '../firebase'; // Ensure your db is properly configured
import './SellerPostsOnIds.css'
const SellersProfiles = () => {
    const [sellers, setSellers] = useState([]);

    useEffect(() => {
        const fetchSellers = async () => {
            const sellersCollection = collection(db, 'sellerIds');
            const sellersSnapshot = await getDocs(sellersCollection);
            const sellersData = await Promise.all(
                sellersSnapshot.docs.map(async (docSnap) => {
                    const profileRef = doc(db, "sellerIds", docSnap.id);
                    const profileSnap = await getDoc(profileRef);
                    return {
                        id: docSnap.id,
                        ...docSnap.data(),
                        profile: profileSnap.exists() ? profileSnap.data() : {}
                    };
                })
            );
            setSellers(sellersData);
        };

        fetchSellers();
    }, []);

    return (
        <div className='container'>
            <div className='row'>
                <div className="sellers-profiles">
                    <h2>Sellers Profiles</h2>
                    <div className="sellers-scroll">
                        {sellers.map((seller) => (
                            <Link to={`/dealers/${seller.id}`} key={seller.id} className="card">
                                <div className="card m-3">
                                    <div className="card-body">
                                        <h5 className="card-title">{seller.profile?.dealerName || seller.dealerName}</h5>
                                        <p className="card-text">District: {seller.profile?.distName || seller.distName}</p>
                                        <p className="card-text">Address: {seller.profile?.address || seller.address}</p>
                                    </div>
                                </div>
                                <img src={seller.profile?.profilePic || 'default-pic-url.jpg'} className="card-img-top" alt={seller.profile?.dealerName || 'Seller'} />

                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SellersProfiles;