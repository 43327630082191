import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import Slider from "react-slick";
import swal from "sweetalert";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './Approve.css';

const ApproveCarPosts = () => {
    const [carPosts, setCarPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState(null);

    const fetchCarPosts = async () => {
        const carPostsCollection = collection(db, 'products');
        const carPostsSnapshot = await getDocs(carPostsCollection);
        const carPostsList = carPostsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCarPosts(carPostsList);
    };

    useEffect(() => {
        fetchCarPosts();
    }, []);

    const handleApprove = async (id) => {
        const confirmed = await swal({
            title: "Are you sure?",
            text: "Once approved, this post will be live!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (confirmed) {
            const postDoc = doc(db, 'products', id);
            await updateDoc(postDoc, { approved: true });
            swal("Approved!", "The car post has been approved.", "success");
            fetchCarPosts(); // Refresh the posts
        }
    };

    const handleDelete = async (id) => {
        const confirmed = await swal({
            title: "Are you sure?",
            text: "Once deleted, this post will be rejected!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (confirmed) {
            const postDoc = doc(db, 'products', id);
            await updateDoc(postDoc, { Reject: true });
            swal("Deleted!", "The car post has been deleted.", "success");
            fetchCarPosts(); // Refresh the posts
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
    };

    const handlePostClick = (post) => {
        setSelectedPost(post);
    };

    const handleCloseModal = () => {
        setSelectedPost(null);
    };

    return (
        <div className="container">
            <h2>Approve Car Posts</h2>
            <div className="row">
                {carPosts.length > 0 ? (
                    carPosts.map(post => (
                        <div className="col-lg-3 col-md-2 col-sm-6" key={post.id}>
                            <div className="card1">
                                <Slider {...settings}>
                                    {post.carPhotos.map((photo, index) => (
                                        <div key={index} className="photo-slide">
                                            <img
                                                src={photo}
                                                alt={`Car ${index + 1}`}
                                                className="img-fluid"
                                            />
                                        </div>
                                    ))}
                                </Slider>
                                <div className="card-body">
                                    <p className="card-text"><strong>{post.carName}</strong></p>
                                    <p className="card-text">Rs.{post.carPrice}, {post.carYear} Model</p>
                                    <button className="button2" onClick={() => handlePostClick(post)} style={{ float: "left" }}>Know More</button>
                                    {post.approved ? (
                                        <button className="button4" disabled>Approved</button>
                                    ) : (
                                        <button className="button1" onClick={() => handleApprove(post.id)}>Approve</button>
                                    )}
                                    <button className="button3" onClick={() => handleDelete(post.id)}>Delete</button>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-lg-12 text-center">
                        <p>No car posts available for approval.</p>
                    </div>
                )}
            </div>

            {selectedPost && (
                <Modal show={true} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedPost.carName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Slider {...settings}>
                            {selectedPost.carPhotos.map((photo, index) => (
                                <div key={index} className="photo-slide">
                                    <img
                                        src={photo}
                                        alt={`Car ${index + 1}`}
                                        className="img-fluid"
                                        style={{ width: "100%", height: "250px" }}
                                    />
                                </div>
                            ))}
                        </Slider>
                        <h5 style={{ marginTop: "25px" }}><strong>Dealer:</strong> {selectedPost.dealerName}</h5>
                        <h5>Price: Rs. {selectedPost.carPrice}</h5>
                        <p><strong>District:</strong> {selectedPost.distName}</p>
                        <p>Kilometers: {selectedPost.carKm} km</p>
                        <p>Year of purchase: {selectedPost.carYear}</p>
                        <p>Description: {selectedPost.carDescription}</p>
                        <p>Engine Size: {selectedPost.engineSize}</p>
                        <p>Fuel Type: {selectedPost.fuelType}</p>
                        <p>Ground Clearance: {selectedPost.groundClearance}</p>
                        <p>Mileage: {selectedPost.mileage} km</p>
                        <p>Safety Rating: {selectedPost.safetyRating} star</p>
                        <p>Seating Capacity: {selectedPost.seatingCapacity} seater</p>
                        <p>Fuel Tank Capacity: {selectedPost.fuelTank} ltr</p>
                        <p>Transmission: {selectedPost.transmission}</p>
                        <p>Warranty: {selectedPost.warranty} years</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                        {!selectedPost.approved && (
                            <Button style={{ margin: "5px" }} variant="success" onClick={() => handleApprove(selectedPost.id)}>
                                Approve
                            </Button>
                        )}
                        <Button variant="danger" onClick={() => handleDelete(selectedPost.id)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default ApproveCarPosts;