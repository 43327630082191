import { useState } from "react";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../firebase"; // Import Firestore and Storage instances
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const AdminSignUp = () => {
    let [email, pickEmail] = useState("");
    let [password, pickPassword] = useState("");
    let [phone, pickPhone] = useState("");
    let [dealerName, pickDealerName] = useState('');
    let [distName, pickDistName] = useState("");
    let [address, pickAddress] = useState('');
    let [profilePic, setProfilePic] = useState(null); // New state for the picture

    const navigate = useNavigate();

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validatePassword = (password) => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,}$/.test(password);
    const validatePhone = (phone) => /^[0-9]{10}$/.test(phone);

    const gologin = async () => {
        const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    
        if (!validateEmail(email)) {
            swal("Invalid Input", "Please enter a valid e-Mail address", "warning");
        } else if (!validatePassword(password)) {
            swal("Invalid Input", "Password should be at least 6 characters long and contain both letters and numbers", "warning");
        } else if (!validatePhone(phone)) {
            swal("Invalid Input", "Please enter a valid 10-digit phone number", "warning");
        } else if (dealerName === "" || distName === "" || address === "" || !profilePic) {
            swal("Invalid Input", "All fields, including the picture, must be filled out", "warning");
        } else if (!validImageTypes.includes(profilePic.type)) {
            swal("Invalid Input", "Only JPG, JPEG, and PNG formats are allowed", "warning");
        } else {
            try {
                // Upload the profile picture to Firebase Storage
                const picRef = ref(storage, `sellerProfilePics/${profilePic.name}`);
                await uploadBytes(picRef, profilePic);
                const picURL = await getDownloadURL(picRef);
    
                // Add the seller details to Firestore
                await addDoc(collection(db, "sellerIds"), {
                    email: email,
                    password: password,
                    phone: phone,
                    dealerName: dealerName,
                    distName: distName,
                    address: address,
                    profilePic: picURL
                });
    
                swal("Success", "User Registered Successfully", "success", { timer: 1500 });
                navigate('/slogin');
            } catch (e) {
                console.error("Error adding document: ", e);
                swal("Error", "Could not register user", "error");
            }
        }
    }
    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                    <div className="card border-0 shadow-lg">
                        <div className="card-header bg-primary text-white">Seller Sign Up</div>
                        <div className="card-body">
                            {/* Other input fields */}
                            <div className="mb-3">
                                <label>e-Mail Id</label>
                                <input type="email" placeholder="Enter your email" className="form-control" onChange={obj => pickEmail(obj.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>Password</label>
                                <input type="password" placeholder="Enter your password" className="form-control" onChange={obj => pickPassword(obj.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>Phone Number</label>
                                <input type="text" placeholder="Enter your 10 digit phone number" className="form-control" onChange={obj => pickPhone(obj.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>Dealer Name</label>
                                <input type="text" placeholder="Enter your dealer name" className="form-control" onChange={obj => pickDealerName(obj.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>District Name</label>
                                <input type="text" placeholder="Enter your district name" className="form-control" onChange={obj => pickDistName(obj.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>Address</label>
                                <input type="text" placeholder="Enter your address" className="form-control" onChange={obj => pickAddress(obj.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>Profile Picture</label>
                                <input type="file" className="form-control" onChange={e => setProfilePic(e.target.files[0])} />
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-danger" onClick={gologin}>
                                Seller Sign Up <i className="fa fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4"></div>
            </div>
        </div>
    )
}

export default AdminSignUp;