import React from "react";
import Bookings from "./CarBookings.js";

const MyBookings = () => {
    const userId = localStorage.getItem("userId");

    return (
        <div className="container mt-3">
            <Bookings userId={userId} />
        </div>
    );
};

export default MyBookings;