import { useState, useRef } from "react";
import swal from "sweetalert";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import imageCompression from "browser-image-compression";
import { db, storage } from "../firebase"; // Adjust the import path as necessary

const NewCarProduct = () => {
    const [carName, setCarName] = useState("");
    const [carPrice, setCarPrice] = useState("");
    const [carPhotos, setCarPhotos] = useState([null, null, null]);
    const [carKm, setCarKm] = useState("");
    const [carYear, setCarYear] = useState("");
    const [carDescription, setCarDescription] = useState("");
    const [fuelType, setFuelType] = useState("");
    const [transmission, setTransmission] = useState("");
    const [engineSize, setEngineSize] = useState("");
    const [mileage, setMileage] = useState("");
    const [safetyRating, setSafetyRating] = useState("");
    const [groundClearance, setGroundClearance] = useState("");
    const [warranty, setWarranty] = useState("");
    const [seatingCapacity, setSeatingCapacity] = useState("");
    const [fuelTank, setFuelTank] = useState("");
    const [uploading, setUploading] = useState(false);

    const currentSellerId = localStorage.getItem("sellerid");

    // Refs for the file inputs
    const fileInputRefs = [useRef(), useRef(), useRef()];

    const handleFileChange = (index, event) => {
        const file = event.target.files[0];
        if (file && ["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
            const files = [...carPhotos];
            files[index] = file;
            setCarPhotos(files);
        } else {
            swal("Error", "Only JPG, JPEG, and PNG formats are allowed.", "error");
            if (fileInputRefs[index].current) {
                fileInputRefs[index].current.value = null; // Reset the file input
            }
        }
    };

    const compressImage = async (imageFile) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.error("Error compressing image:", error);
            return imageFile; // Return original file if compression fails
        }
    };

    const uploadPhotos = async () => {
        const photoUrls = [];
        for (const photo of carPhotos) {
            if (photo) {
                const compressedPhoto = await compressImage(photo);
                const photoRef = ref(storage, `car_photos/${Date.now()}_${compressedPhoto.name}`);
                await uploadBytes(photoRef, compressedPhoto);
                const url = await getDownloadURL(photoRef);
                photoUrls.push(url);
            }
        }
        return photoUrls;
    };

    const validateForm = () => {
        if (!carName || !carPrice || !carYear || !fuelType || !transmission || !engineSize || !mileage || !safetyRating || !warranty || !seatingCapacity || !fuelTank) {
            swal("Error", "Please fill in all required fields.", "error");
            return false;
        }

        if (!carPhotos.some(photo => photo)) {
            swal("Error", "Please upload at least one car photo.", "error");
            return false;
        }

        return true;
    };

    const save = async () => {
        if (!currentSellerId) {
            swal("Error", "Seller ID is missing. Please log in again.", "error");
            return;
        }

        if (!validateForm()) {
            return;
        }

        setUploading(true);
        try {
            const photoUrls = await uploadPhotos();

            const newProduct = {
                sellerId: currentSellerId,
                carName,
                carPrice,
                carPhotos: photoUrls,
                carKm,
                carYear,
                carDescription,
                fuelType,
                transmission,
                engineSize,
                mileage,
                safetyRating,
                groundClearance,
                warranty,
                seatingCapacity,
                fuelTank
            };

            await addDoc(collection(db, "products"), newProduct);

            swal(newProduct.carName, "Uploaded Successfully!", "success");

            // Reset form fields
            setCarName("");
            setCarPrice("");
            setCarPhotos([null, null, null]);
            setCarKm("");
            setCarYear("");
            setCarDescription("");
            setFuelType("");
            setTransmission("");
            setEngineSize("");
            setMileage("");
            setSafetyRating("");
            setGroundClearance("");
            setWarranty("");
            setSeatingCapacity("");
            setFuelTank("");

            // Clear file input fields
            fileInputRefs.forEach(ref => {
                if (ref.current) {
                    ref.current.value = null;
                }
            });
        } catch (error) {
            swal("Error", "Could not upload the product. Please try again.", "error");
            console.error("Error uploading product:", error);
        } finally {
            setUploading(false);
        }
    };

    const years = Array.from({ length: new Date().getFullYear() - 1999 }, (_, i) => 2000 + i);
    const fuelTypes = ["Petrol", "Diesel", "Electric"];
    const transmissions = ["Automatic", "Manual"];
    const engineSizes = ["500cc","600cc","700cc","800cc","1000cc", "1200cc", "1500cc", "2000cc", "2500cc"];
    const safetyRatings = [1, 2, 3, 4, 5];
    const warranties = Array.from({ length: 10 }, (_, i) => i + 1);
    const seatingCapacities = Array.from({ length: 5 }, (_, i) => 5 + i);
    const fuelTanks = Array.from({ length: 50 }, (_, i) => i + 1);

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-12 text-center mb-4">
                    <h3 className="text-primary">Enter Car/Vehicles/Truck Details</h3>
                </div>

                <div className="col-lg-4">
                    <p>Enter Vehicle Name</p>
                    <input type="text" className="form-control" value={carName}
                      placeholder="Ex: Verna sx"   onChange={e => setCarName(e.target.value)} />
                </div>

                <div className="col-lg-4">
                    <p>Enter Vehicles Price</p>
                    <input type="text" className="form-control" value={carPrice}
                      placeholder="Enter a price "   onChange={e => setCarPrice(e.target.value)} />
                </div>

                <div className="col-lg-4">
                    <p>Enter Vehicles Kilometers Driven</p>
                    <input type="text" className="form-control" value={carKm}
                     placeholder="Ex: 50000" onChange={e => setCarKm(e.target.value)} />
                </div>

                <div className="col-lg-4">
                    <p>Purchased Year</p>
                    <select className="form-control" value={carYear}
                        onChange={e => setCarYear(e.target.value)}>
                        <option value="">Select Year</option>
                        {years.map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>

                <div className="col-lg-12">
                    <p>Enter Car Description</p>
                    <textarea className="form-control" value={carDescription}
                      placeholder="Ex: Fully working car, no damage" onChange={e => setCarDescription(e.target.value)}></textarea>
                </div>

                <div className="col-lg-4">
                    <p>Enter Fuel Type</p>
                    <select className="form-control" value={fuelType}
                        onChange={e => setFuelType(e.target.value)}>
                        <option value="">Select Fuel Type</option>
                        {fuelTypes.map(type => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>

                <div className="col-lg-4">
                    <p>Enter Transmission</p>
                    <select className="form-control" value={transmission}
                        onChange={e => setTransmission(e.target.value)}>
                        <option value="">Select Transmission</option>
                        {transmissions.map(type => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>

                <div className="col-lg-4">
                    <p>Enter Engine Size</p>
                    <select className="form-control" value={engineSize}
                        onChange={e => setEngineSize(e.target.value)}>
                        <option value="">Select Engine Size</option>
                        {engineSizes.map(size => (
                            <option key={size} value={size}>{size}</option>
                        ))}
                    </select>
                </div>

                <div className="col-lg-4">
                    <p>Enter Mileage</p>
                    <input type="text" className="form-control" value={mileage}
                        onChange={e => setMileage(e.target.value)} />
                </div>

                <div className="col-lg-4">
                    <p>Enter Safety Rating</p>
                    <select className="form-control" value={safetyRating}
                        onChange={e => setSafetyRating(e.target.value)}>
                        <option value="">Select Safety Rating</option>
                        {safetyRatings.map(rating => (
                            <option key={rating} value={rating}>{rating} Star</option>
                        ))}
                    </select>
                </div>

                <div className="col-lg-4">
                    <p>Enter Ground Clearance</p>
                    <input type="text" className="form-control" value={groundClearance}
                      placeholder="Ex: clear"  onChange={e => setGroundClearance(e.target.value)} />
                </div>

                <div className="col-lg-4">
                    <p>Enter Warranty (Years)</p>
                    <select className="form-control" value={warranty}
                        onChange={e => setWarranty(e.target.value)}>
                        <option value="">Select Warranty</option>
                        {warranties.map(year => (
                            <option key={year} value={year}>{year} Years</option>
                        ))}
                    </select>
                </div>

                <div className="col-lg-4">
                    <p>Enter Seating Capacity</p>
                    <select className="form-control" value={seatingCapacity}
                        onChange={e => setSeatingCapacity(e.target.value)}>
                        <option value="">Select Seating Capacity</option>
                        {seatingCapacities.map(capacity => (
                            <option key={capacity} value={capacity}>{capacity} Seats</option>
                        ))}
                    </select>
                </div>


                <div className="col-lg-4">
                    <p>Enter Fuel Tank Capacity (Liters)</p>
                    <select className="form-control" value={fuelTank}
                        onChange={e => setFuelTank(e.target.value)}>
                        <option value="">Select Fuel Tank Capacity</option>
                        {fuelTanks.map(capacity => (
                            <option key={capacity} value={capacity}>{capacity} Liters</option>
                        ))}
                    </select>
                </div>

                {Array.from({ length: 3 }, (_, i) => (
                    <div key={i} className="col-lg-4">
                        <p>Upload Car Photo {i + 1}</p>
                        <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={e => handleFileChange(i, e)}
                            ref={fileInputRefs[i]}
                        />
                    </div>
                ))}

                <div className="col-lg-12 text-center">
                    <button className="btn btn-primary mt-4" onClick={save} disabled={uploading}>
                        {uploading ? "Uploading..." : "Save"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NewCarProduct;