import { useState } from "react";
import swal from "sweetalert";
import { db } from "../firebase"; // Import the Firestore database instance
import { collection, addDoc } from "firebase/firestore";
import { Link } from "react-router-dom"; // Import useNavigate for programmatic navigation

const UserSignUp = () => {
    let [email, pickEmail] = useState("");
    let [password, pickPassword] = useState("");
    let [phone, pickPhone] = useState("");
    let [name, pickName] = useState('');

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validatePassword = (password) => {
        return password.length >= 6;
    };

    const validatePhone = (phone) => {
        const re = /^\d{10}$/;
        return re.test(phone);
    };

    const validateName = (name) => {
        return name.trim().length > 0;
    };

    const gologin = async () => {
        if (!validateName(name)) {
            swal("Invalid Input", "Please enter your name", "warning");
            return;
        }
        if (!validateEmail(email)) {
            swal("Invalid Input", "Please enter a valid e-mail address", "warning");
            return;
        }
        if (!validatePassword(password)) {
            swal("Invalid Input", "Password must be at least 6 characters long", "warning");
            return;
        }
        if (!validatePhone(phone)) {
            swal("Invalid Input", "Please enter a valid 10-digit phone number", "warning");
            return;
        }

        try {
            const docRef = await addDoc(collection(db, "UsersIds"), {
                email: email,
                password: password,
                phone: phone,
                Name: name
            });
            swal("Success", "User Registered Successfully", "success", { timer: 1500 });
            // localStorage.setItem("UserName", name);
            // localStorage.setItem("UserPhone", phone);
            window.location.href = '/login';
        } catch (e) {
            console.error("Error adding document: ", e);
            swal("Error", "Could not register user", "error");
        }
    }

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                    <div className="card border-0 shadow-lg">
                        <div className="card-header bg-primary text-white">User Sign Up</div>
                        <div className="card-body">
                            <div className="mb-3">
                                <label>Name</label>
                                <input type="text" placeholder="Enter your Name" className="form-control" onChange={obj => pickName(obj.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>e-Mail Id</label>
                                <input type="email" placeholder="yourmail@gmail.com" className="form-control" onChange={obj => pickEmail(obj.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>Password</label>
                                <input type="password" placeholder="Enter 6 digit password" className="form-control" onChange={obj => pickPassword(obj.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label>Mobile Number</label>
                                <input type="text" placeholder="Enter your 10 digit mobile number" className="form-control" onChange={obj => pickPhone(obj.target.value)} />
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-danger m-2" onClick={gologin}>
                                User Sign Up <i className="fa fa-arrow-right"></i>
                            </button>
                            <div>
                            <Link to="/login">
                                <b>
                                    Sign-In
                                </b>
                            </Link>
                        </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-4"></div>
            </div>
        </div>
    )
}

export default UserSignUp;