import React, { useState } from 'react';
import Slider from 'react-slick';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore';
import { db } from "../firebase";
import './HorizontalScroll.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const HorizontalScrollProducts = ({ products }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const navigate = useNavigate();
    const currentUserId = localStorage.getItem("userId");

    // Sort products from lowest to highest price
    const sortedProducts = [...products].sort((a, b) => a.carPrice - b.carPrice);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    const modalSliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    const handleBookNow = async (product) => {
        if (!currentUserId) {
            // Redirect to signup if user is not logged in
            swal("Please log in to book this car.");
            navigate('/login');
            return;
        }

        try {
            // Add booking to Firestore
            const bookingData = {
                ...product,
                userId: currentUserId,
                sellerId: product.sellerId,
                bookingDate: new Date(),
            };
            await addDoc(collection(db, "bookings"), bookingData);
            swal("Success", "Car booked successfully!", "success");
        } catch (error) {
            swal("Error", "Could not book the car. Please try again.", "error");
            console.error("Error booking car:", error);
        }
    };

    const handleCloseModal = () => {
        setSelectedProduct(null);
    };

    return (
        <div className='bg-white'>
            <div className='container'>
                <h5 className="section-title"> Used Cars Starting with the lowest price</h5>
            </div>
            <div className="horizontal-scroll-products">
                <Slider {...sliderSettings}>
                    {sortedProducts.map(product => (
                        <div key={product.id} className="card3 bg-transparent">
                            <Slider {...modalSliderSettings}>
                                {product.carPhotos.map((photo, index) => (
                                    <div key={index} className="photo-slide">
                                        <img
                                            src={photo}
                                            alt={`Car ${index + 1}`}
                                            className="img-fluid"
                                        />
                                    </div>
                                ))}
                            </Slider>
                            <div className="card-body">
                                <p className="card-text"><strong>{product.carName}</strong></p>
                                <p className="card-text">Rs. {product.carPrice}, {product.carYear} Model</p>
                                <p className="card-text">Dealer: {product.dealerName}</p>
                                <button className="button2" onClick={() => handleProductClick(product)}>Know More</button>
                                <button className="button1" onClick={() => handleBookNow(product)}>Book Now</button>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            {selectedProduct && (
                <Modal show={true} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedProduct.carName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Slider {...modalSliderSettings}>
                            {selectedProduct.carPhotos.map((photo, index) => (
                                <div key={index} className="photo-slide">
                                    <img
                                        src={photo}
                                        alt={`Car ${index + 1}`}
                                        className="img-fluid"
                                        style={{ width: "100%", height: "250px" }}
                                    />
                                </div>
                            ))}
                        </Slider>
                        <h5 style={{ marginTop: "25px" }}><strong>Dealer:</strong> {selectedProduct.dealerName}</h5>
                        <h5>Price: Rs. {selectedProduct.carPrice}</h5>
                        <p><strong>District:</strong> {selectedProduct.distName}</p>
                        <p>Kilometers: {selectedProduct.carKm} km</p>
                        <p>Year of Purchase: {selectedProduct.carYear}</p>
                        <p>Description: {selectedProduct.carDescription}</p>
                        <p>Engine Size: {selectedProduct.engineSize}</p>
                        <p>Fuel Type: {selectedProduct.fuelType}</p>
                        <p>Ground Clearance: {selectedProduct.groundClearance}</p>
                        <p>Mileage: {selectedProduct.mileage} km</p>
                        <p>Safety Rating: {selectedProduct.safetyRating} star</p>
                        <p>Seating Capacity: {selectedProduct.seatingCapacity} seater</p>
                        <p>Fuel Tank Capacity: {selectedProduct.fuelTankCapacity} ltr</p>
                        <p>Transmission: {selectedProduct.transmission}</p>
                        <p>Warranty: {selectedProduct.warranty} years</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => handleBookNow(selectedProduct)}>
                            Book Now
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default HorizontalScrollProducts;