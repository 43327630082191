import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import swal from "sweetalert";

const Bookings = ({ userId }) => {
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                setLoading(true);
                const bookingsRef = collection(db, "bookings");
                const bookingsQuery = query(bookingsRef, where("userId", "==", userId));
                const bookingsSnapshot = await getDocs(bookingsQuery);

                const bookingsData = bookingsSnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        ...data,
                        type: data.bikeName ? 'bike' : 'car', // Determine type based on the presence of bikeName field
                        bookingDate: data.bookingDate ? data.bookingDate.toDate() : null, // Convert Firestore timestamp to Date object
                    };
                });

                setBookings(bookingsData);
            } catch (error) {
                console.error("Error fetching bookings:", error);
                setError("Could not fetch bookings. Please try again.");
                swal("Error", "Could not fetch bookings. Please try again.", "error");
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchBookings();
        } else {
            setError("You must be logged in to view your bookings.");
            setLoading(false);
        }
    }, [userId]);

    if (loading) {
        return <div className="text-center mt-3">Loading your bookings...</div>;
    }

    if (error) {
        return <div className="text-center mt-3">{error}</div>;
    }

    return (
        <div className="container mt-3">
            <h2>My Bookings</h2>
            <div className="row">
                {bookings.length > 0 ? (
                    bookings.map(booking => (
                        <div className="col-lg-2 col-md-6 col-sm-8" key={booking.id}>
                            <div className="card">
                                <img
                                    src={booking.type === 'bike' ? booking.bikePhotos && booking.bikePhotos[0] : booking.carPhotos && booking.carPhotos[0]}
                                    alt={booking.type === 'bike' ? booking.bikeName : booking.carName}
                                    className="img-fluid lazyload"
                                    style={{ width: "100%", height: "150px" }}
                                />
                                <div className="card-body">
                                    <h5 className="card-title">{booking.type === 'bike' ? booking.bikeName : booking.carName}</h5>
                                    <p className="card-text"><strong>Price:</strong> Rs. {booking.type === 'bike' ? booking.bikePrice : booking.carPrice}</p>
                                    <p className="card-text"><strong>Booking Date:</strong>{booking.bookingDate ? new Date(booking.bookingDate.seconds * 1000).toLocaleDateString() : "N/A"}</p>
                                    <p className="card-text"><strong>Dealer:</strong> {booking.dealerName}</p>
                                    <p className="card-text"><strong>District:</strong> {booking.distName}</p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-lg-12 text-center">
                        <p>You have not booked any vehicles yet.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Bookings;