import ApproveCarPosts from './ApproveCarPosts';
import ApproveBikePosts from './ApproveBikePosts.js';
import './Approve.css';
function AdminDashboard() {
    return (
        <div>
            <h1>Admin Dashboard</h1>
            <ApproveCarPosts />
            <ApproveBikePosts />
        </div>
    );
}

export default AdminDashboard;