import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, query, where, getDocs,addDoc, limit, startAfter } from "firebase/firestore";
import { db } from "../firebase";
import Slider from "react-slick";
import swal from "sweetalert";
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './SellerPostsOnIds.css';
import SellerBikesOnIds from "./SellerBikesOnIds";
import './allPosts.css'
const SellerPostsOnIds = () => {
  const { sellerId } = useParams();
  const [products, setProducts] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [productIds, setProductIds] = useState(new Set());

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const limitPerPage = 8; // Number of products to load per page
  const navigate = useNavigate();
  const currentUserId = localStorage.getItem("userId");

  const fetchProducts = async (lastDoc = null) => {
    try {
      setLoading(true);
      const productsRef = collection(db, "products");
      const q = query(
        productsRef,
        where("sellerId", "==", sellerId),
        limit(limitPerPage),
        ...(lastDoc ? [startAfter(lastDoc)] : [])
      );

      const querySnapshot = await getDocs(q);
      const productsData = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(product => !productIds.has(product.id)); // Filter out duplicates

      setProductIds(prevIds => new Set([...prevIds, ...productsData.map(product => product.id)]));

      if (productsData.length < limitPerPage) {
        setHasMoreProducts(false);
      }

      if (querySnapshot.docs.length > 0) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }

      setProducts(prevProducts => {
        const newProducts = productsData.filter(
          product => !prevProducts.some(prevProduct => prevProduct.id === product.id)
        );
        return [...prevProducts, ...newProducts];
      });
    } catch (error) {
      swal("Error", "Could not fetch products. Please try again.", "error");
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setProducts([]);
    setProductIds(new Set());
    setLastVisible(null);
    setHasMoreProducts(true);
    fetchProducts();
  }, [sellerId]);

  useEffect(() => {
    if (inView && !loading && hasMoreProducts) {
      fetchProducts(lastVisible);
    }
  }, [inView, lastVisible, loading, hasMoreProducts]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };
  const handleBookNow = async (product) => {
    if (!currentUserId) {
        // Redirect to signup if user is not logged in
        swal("Please log in to book this car.");
        navigate('/login');
        return;
    }

    try {
        // Add booking to Firestore
        const bookingData = {
            ...product,
            userId: currentUserId,
            sellerId: product.sellerId,
            bookingDate: new Date(),
        };
        await addDoc(collection(db, "bookings"), bookingData);
        swal("Success", "Car booked successfully!", "success");
    } catch (error) {
        swal("Error", "Could not book the car. Please try again.", "error");
        console.error("Error booking car:", error);
    }
};
  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  return (
    <div className="container mt-3">
      <h2>Posts by Seller</h2>
      <SellerBikesOnIds/>
      <div className="row">
        {products.length > 0 ? (
          products.map((product, index) => (
            <div className="col-lg-3 mb-3" key={product.id} ref={index === products.length - 1 ? ref : null}>
              <div className="card2 shadow-lg">
                <Slider {...settings}>
                  {product.carPhotos.map((photo, index) => (
                    <div key={index} className="photo-slide">
                      <img
                        src={photo}
                        alt={`Car ${index + 1}`}
                        className="img-fluid lazyload"
                        style={{ width: "100%", height: "200px" }}
                      />
                    </div>
                  ))}
                </Slider>
                <div className="card-body">
                  <h4 className="card-text">{product.carName}</h4>
                  <h5 className="card-text">Rs. {product.carPrice}</h5>
                  <p className="card-text">{product.carKm} km, {product.carYear} Model</p>
                  <button className="button2" onClick={() => handleProductClick(product)} style={{ float: "left" }}>Know More</button>
                  <button className="button1" onClick={() => handleBookNow(product)}>Book Now</button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-lg-12 text-center">
            <p>No products found.</p>
          </div>
        )}
      </div>

      {loading && (
        <div className="col-lg-12 text-center">
          <p>Loading more products...</p>
        </div>
      )}

      {selectedProduct && (
        <Modal show={true} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedProduct.carName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Slider {...settings}>
              {selectedProduct.carPhotos.map((photo, index) => (
                <div key={index} className="photo-slide">
                  <img
                    src={photo}
                    alt={`Car ${index + 1}`}
                    className="img-fluid"
                    style={{ width: "100%", height: "250px" }}
                  />
                </div>
              ))}
            </Slider>
            <h5>Price: Rs. {selectedProduct.carPrice} -only</h5>
            <p>Kilometers: {selectedProduct.carKm} Km</p>
            <p>Year: {selectedProduct.carYear}</p>
            <p>Description: {selectedProduct.carDescription}</p>
            <p>Engine Size: {selectedProduct.engineSize}</p>
            <p>Fuel Type: {selectedProduct.fuelType}</p>
            <p>Ground Clearance: {selectedProduct.groundClearance}</p>
            <p>Mileage: {selectedProduct.mileage}</p>
            <p>Safety Rating: {selectedProduct.safetyRating}</p>
            <p>Seating Capacity: {selectedProduct.seatingCapacity}</p>
            <p>Size: {selectedProduct.size}</p>
            <p>Fuel Tank Capacity: {selectedProduct.fuelTank}</p>
            <p>Transmission: {selectedProduct.transmission}</p>
            <p>Warranty: {selectedProduct.warranty}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default SellerPostsOnIds;