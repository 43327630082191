import React, { useState } from "react";
import './Tabs.css'
import AllPosts from "./allPosts";
import Carousel from "./Carousel";
import BikePosts from "./Bikes";
import SellerProfiles from "./SellersProfiles";
const TabsComponent = () => {
    const [activeTab, setActiveTab] = useState("tab1");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="tabs-container">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button
                        className={`nav-tab ${activeTab === "tab1" ? "active" : ""}`}
                        onClick={() => handleTabClick("tab1")}
                    >
                        Home
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-tab ${activeTab === "tab2" ? "active" : ""}`}
                        onClick={() => handleTabClick("tab2")}
                    >
                        Bike's
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-tab ${activeTab === "tab3" ? "active" : ""}`}
                        onClick={() => handleTabClick("tab3")}
                    >
                        Car's
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-tab ${activeTab === "tab4" ? "active" : ""}`}
                        onClick={() => handleTabClick("tab4")}
                    >
                        Dealers
                    </button>
                </li>
            </ul>

            <div className="tab-content">
                {activeTab === "tab1" && (
                    <div className="tab-pane active">
                        <div>
                            <Carousel />
                        </div>
                        <AllPosts />
                        <BikePosts />
                    </div>
                )}
                {activeTab === "tab2" && (
                    <div className="tab-pane active">
                        <div>
                            <BikePosts />
                        </div>
                    </div>
                )}
                {activeTab === "tab3" && (
                    <div className="tab-pane active">
                        <AllPosts />
                    </div>
                )}
                {activeTab === "tab4" && (
                    <div className="tab-pane active">
                        <SellerProfiles />
                    </div>
                )}
            </div>
        </div>
    );
};

export default TabsComponent;