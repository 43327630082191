import { useState } from "react";
import swal from "sweetalert";
import { db } from "../firebase"; // Import the Firebase Firestore instance
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom"; // Import useNavigate for programmatic navigation

const Adminlogin = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook

    let [email, pickEmail] = useState("");
    let [password, pickPassword] = useState("");

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const validatePassword = (password) => {
        return password.length > 6;
    }

    const gologin = async () => {
        if (!validateEmail(email)) {
            swal("Invalid Input", "Please enter a valid e-Mail address", "warning");
        } else if (!validatePassword(password)) {
            swal("Invalid Input", "Password should be at least 6 characters long and contain letters, numbers, and special characters", "warning");
        } else {
            try {
                const q = query(collection(db, "sellerIds"), where("email", "==", email.trim()), where("password", "==", password.trim()));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const userInfo = querySnapshot.docs[0].data();
                    localStorage.setItem("sellerid", querySnapshot.docs[0].id);
                    localStorage.setItem("sellername", userInfo.dealerName); // Assuming 'dealerName' is the full name field
                    swal("Success", "Login Successful", "success", { timer: 1500 }).then(() => {
                        navigate('/');  // Use navigate to redirect after successful login
                        window.location.reload();
                        });

                } else {
                    swal("Invalid", "Email or Password is incorrect", "warning");
                }
            } catch (error) {
                console.error("Error during login: ", error);
                swal("Error", "Could not login", "error");
            }
        }
    }

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                    <div className="card border-0 shadow-lg">
                        <div className="card-header bg-primary text-white"> Seller Login</div>
                        <div className="card-body">
                            <div className="mb-3">
                                <label>e-Mail Id</label>
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="Enter your email" 
                                    onChange={obj => pickEmail(obj.target.value)} 
                                />
                            </div>
                            <div className="mb-3">
                                <label>Password</label>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    placeholder="Enter your password" 
                                    onChange={obj => pickPassword(obj.target.value)} 
                                />
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-danger" onClick={gologin}>
                                Login <i className="fa fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4"></div>
            </div>
        </div>
    )
}

export default Adminlogin;