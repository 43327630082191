import { useEffect, useState } from "react";
import { collection, query, getDocs, limit, startAfter, doc, getDoc, addDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import Slider from "react-slick";
import swal from "sweetalert";
import { useInView } from 'react-intersection-observer';
import './allPosts.css'; // Reuse the same CSS styles
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

const BikePosts = () => {
    const [bikes, setBikes] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedBike, setSelectedBike] = useState(null);
    const [hasMoreBikes, setHasMoreBikes] = useState(true);
    const [bikeIds, setBikeIds] = useState(new Set());

    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    const navigate = useNavigate();
    const currentUserId = localStorage.getItem("userId");
    const limitPerPage = 8; // Number of bikes to load per page

    const fetchBikes = async (lastDoc = null) => {
        try {
            setLoading(true);
            const bikesRef = collection(db, "bikes");
            const q = query(
                bikesRef,
                where("approved", "==", true), // Only fetch approved bikes
                limit(limitPerPage),
                ...(lastDoc ? [startAfter(lastDoc)] : [])
            );

            const querySnapshot = await getDocs(q);
            const bikesData = querySnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(bike => !bikeIds.has(bike.id)); // Filter out duplicates

            // Fetch dealer details
            const updatedBikesData = await Promise.all(
                bikesData.map(async (bike) => {
                    const sellerDocRef = doc(db, "sellerIds", bike.sellerId);
                    const sellerDoc = await getDoc(sellerDocRef);
                    if (sellerDoc.exists()) {
                        const sellerData = sellerDoc.data();
                        return {
                            ...bike,
                            dealerName: sellerData.dealerName,
                            distName: sellerData.distName,
                        };
                    }
                    return bike;
                })
            );

            setBikeIds(prevIds => new Set([...prevIds, ...updatedBikesData.map(bike => bike.id)]));

            if (updatedBikesData.length < limitPerPage) {
                setHasMoreBikes(false);
            }

            if (querySnapshot.docs.length > 0) {
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            }

            setBikes(prevBikes => {
                const newBikes = updatedBikesData.filter(
                    bike => !prevBikes.some(prevBike => prevBike.id === bike.id)
                );
                return [...prevBikes, ...newBikes];
            });
        } catch (error) {
            swal("Error", "Could not fetch bikes. Please try again.", "error");
            console.error("Error fetching bikes:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setBikes([]);
        setBikeIds(new Set());
        setLastVisible(null);
        setHasMoreBikes(true);
        fetchBikes();
    }, [currentUserId]);

    useEffect(() => {
        if (inView && !loading && hasMoreBikes) {
            fetchBikes(lastVisible);
        }
    }, [inView, lastVisible, loading, hasMoreBikes]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
    };

    const handleBikeClick = (bike) => {
        setSelectedBike(bike);
    };

    const handleBookNow = async (bike) => {
        if (!currentUserId) {
            // Redirect to signup if user is not logged in
            swal("Please log in to book this bike.");
            navigate('/login');
            return;
        }

        try {
            // Add booking to Firestore
            const bookingData = {
                ...bike,
                userId: currentUserId,
                sellerId: bike.sellerId,
                bookingDate: new Date(),
            };
            await addDoc(collection(db, "bookings"), bookingData);
            swal("Success", "Bike booked successfully!", "success");
        } catch (error) {
            swal("Error", "Could not book the bike. Please try again.", "error");
            console.error("Error booking bike:", error);
        }
    };

    const handleCloseModal = () => {
        setSelectedBike(null);
    };

    return (
        <div className="">
            <div className="container">
            <h5 className="section-title">Newly added used bikes </h5>
            </div>
            <div className="row">
                {bikes.length > 0 ? (
                    bikes.map((bike, index) => (
                        <div className="col-lg-2 col-md-4 col-sm-6" key={bike.id} ref={index === bikes.length - 1 ? ref : null}>
                            <div className="card2 bg-warning">
                                <Slider {...settings}>
                                    {bike.bikePhotos.map((photo, index) => (
                                        <div key={index} className="photo-slide">
                                            <img
                                                src={photo}
                                                alt={`Bike ${index + 1}`}
                                                className="img-fluid"
                                            />
                                        </div>
                                    ))}
                                </Slider>
                                <div className="card-body">
                                    <p className="card-text"><strong>{bike.bikeName}</strong></p>
                                    <p className="card-text">Rs.{bike.bikePrice}, {bike.bikeYear} Model</p>
                                    <p className="card-text">Dealer:{bike.dealerName}</p>
                                    <button className="button2" onClick={() => handleBikeClick(bike)} style={{ float: "left" }}>Know More</button>
                                    <button className="button1" onClick={() => handleBookNow(bike)}>Book Now</button>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-lg-12 text-center">
                        <p>Swipe down to reload</p>
                    </div>
                )}
            </div>

            {loading && (
                <div className="col-lg-12 text-center">
                    <p>Loading bikes...</p>
                </div>
            )}

            {selectedBike && (
                <Modal show={true} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedBike.bikeName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Slider {...settings}>
                            {selectedBike.bikePhotos.map((photo, index) => (
                                <div key={index} className="photo-slide">
                                    <img
                                        src={photo}
                                        alt={`Bike ${index + 1}`}
                                        className="img-fluid"
                                        style={{ width: "100%", height: "250px" }}
                                    />
                                </div>
                            ))}
                        </Slider>
                        <h5 style={{marginTop:"20px"}}>Price: Rs. {selectedBike.bikePrice}</h5>
                        <p><strong>Dealer:</strong> {selectedBike.dealerName}</p>
                        <p><strong>District:</strong> {selectedBike.distName}</p>
                        <p>Kilometers ran: {selectedBike.kilometersRan} km</p>
                        <p>Year of purchase: {selectedBike.bikeYear}</p>
                        <p>Description: {selectedBike.bikeDescription}</p>
                        <p>Engine Size: {selectedBike.engineSize}</p>
                        <p>Fuel Type: {selectedBike.fuelType}</p>
                        <p>Mileage: {selectedBike.mileage} km</p>
                        <p>Fuel Tank Capacity: {selectedBike.fuelTank} ltr</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button style={{ margin: "5px" }} variant="success" onClick={() => handleBookNow(selectedBike)}>
                            Book Now
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default BikePosts;