import { useState, useRef } from "react";
import swal from "sweetalert";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import imageCompression from "browser-image-compression";
import { db, storage } from "../firebase"; // Adjust the import path as necessary

const NewBikeProduct = () => {
    const [bikeName, setBikeName] = useState("");
    const [bikePrice, setBikePrice] = useState("");
    const [bikePhotos, setBikePhotos] = useState([null, null, null]);
    const [bikeYear, setBikeYear] = useState("");
    const [fuelType, setFuelType] = useState("");
    const [engineSize, setEngineSize] = useState("");
    const [mileage, setMileage] = useState("");
    const [fuelTank, setFuelTank] = useState("");
    const [kilometersRan, setKilometersRan] = useState(""); // New state for kilometers ran
    const [uploading, setUploading] = useState(false);

    const currentSellerId = localStorage.getItem("sellerid");

    // Refs for the file inputs
    const fileInputRefs = [useRef(), useRef(), useRef()];

    const handleFileChange = (index, event) => {
        const file = event.target.files[0];
        if (file && ["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
            const files = [...bikePhotos];
            files[index] = file;
            setBikePhotos(files);
        } else {
            swal("Error", "Only JPG, JPEG, and PNG formats are allowed.", "error");
            if (fileInputRefs[index].current) {
                fileInputRefs[index].current.value = null; // Reset the file input
            }
        }
    };

    const compressImage = async (imageFile) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.error("Error compressing image:", error);
            return imageFile; // Return original file if compression fails
        }
    };

    const uploadPhotos = async () => {
        const photoUrls = [];
        for (const photo of bikePhotos) {
            if (photo) {
                const compressedPhoto = await compressImage(photo);
                const photoRef = ref(storage, `bike_photos/${Date.now()}_${compressedPhoto.name}`);
                await uploadBytes(photoRef, compressedPhoto);
                const url = await getDownloadURL(photoRef);
                photoUrls.push(url);
            }
        }
        return photoUrls;
    };

    const validateForm = () => {
        if (!bikeName || !bikePrice || !bikeYear || !fuelType || !engineSize || !mileage || !fuelTank || !kilometersRan) {
            swal("Error", "Please fill in all required fields.", "error");
            return false;
        }

        if (bikePhotos.filter(photo => photo).length < 2) {
            swal("Error", "Please upload at least two bike photos.", "error");
            return false;
        }

        return true;
    };

    const save = async () => {
        if (!currentSellerId) {
            swal("Error", "Seller ID is missing. Please log in again.", "error");
            return;
        }

        if (!validateForm()) {
            return;
        }

        setUploading(true);
        try {
            const photoUrls = await uploadPhotos();

            const newProduct = {
                sellerId: currentSellerId,
                bikeName,
                bikePrice,
                bikePhotos: photoUrls,
                bikeYear,
                fuelType,
                engineSize,
                mileage,
                fuelTank,
                kilometersRan // Include the new field
            };

            await addDoc(collection(db, "bikes"), newProduct);

            swal(newProduct.bikeName, "Uploaded Successfully!", "success");

            // Reset form fields
            setBikeName("");
            setBikePrice("");
            setBikePhotos([null, null, null]);
            setBikeYear("");
            setFuelType("");
            setEngineSize("");
            setMileage("");
            setFuelTank("");
            setKilometersRan(""); // Reset the new field

            // Clear file input fields
            fileInputRefs.forEach(ref => {
                if (ref.current) {
                    ref.current.value = null;
                }
            });
        } catch (error) {
            swal("Error", "Could not upload the product. Please try again.", "error");
            console.error("Error uploading product:", error);
        } finally {
            setUploading(false);
        }
    };

    const years = Array.from({ length: new Date().getFullYear() - 1999 }, (_, i) => 2000 + i);
    const fuelTypes = ["Petrol", "Diesel", "Electric"];
    const engineSizes = ["100cc", "150cc", "200cc", "250cc", "500cc", "600cc", "1000cc"];
    const fuelTanks = Array.from({ length: 50 }, (_, i) => i + 1);

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-12 text-center mb-4">
                    <h3 className="text-primary">Enter Bike Details</h3>
                </div>

                <div className="col-lg-4">
                    <p>Enter Bike Name</p>
                    <input type="text" placeholder="Bike Name" className="form-control" value={bikeName}
                        onChange={e => setBikeName(e.target.value)} />
                </div>

                <div className="col-lg-4">
                    <p>Enter Bike Price</p>
                    <input type="text" placeholder="Bike Price" className="form-control" value={bikePrice}
                        onChange={e => setBikePrice(e.target.value)} />
                </div>

                <div className="col-lg-4">
                    <p>Purchased Year</p>
                    <select className="form-control" value={bikeYear}
                        onChange={e => setBikeYear(e.target.value)}>
                        <option value="">Select Year</option>
                        {years.map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>

                <div className="col-lg-4">
                    <p>Enter Fuel Type</p>
                    <select className="form-control" value={fuelType}
                        onChange={e => setFuelType(e.target.value)}>
                        <option value="">Select Fuel Type</option>
                        {fuelTypes.map(type => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>

                <div className="col-lg-4">
                    <p>Enter Engine Size</p>
                    <select className="form-control" value={engineSize}
                        onChange={e => setEngineSize(e.target.value)}>
                        <option value="">Select Engine Size</option>
                        {engineSizes.map(size => (
                            <option key={size} value={size}>{size}</option>
                        ))}
                    </select>
                </div>

                <div className="col-lg-4">
                    <p>Enter Mileage</p>
                    <input type="text" placeholder="Ex: 15" className="form-control" value={mileage}
                        onChange={e => setMileage(e.target.value)} />
                </div>

                <div className="col-lg-4">
                    <p>Enter Fuel Tank Capacity (Liters)</p>
                    <select className="form-control" value={fuelTank}
                        onChange={e => setFuelTank(e.target.value)}>
                        <option value="">Select Fuel Tank Capacity</option>
                        {fuelTanks.map(capacity => (
                            <option key={capacity} value={capacity}>{capacity} Liters</option>
                        ))}
                    </select>
                </div>

                <div className="col-lg-4">
                    <p>Enter Kilometers Ran</p>
                    <input type="text" placeholder="Kilometers Ran" className="form-control" value={kilometersRan}
                        onChange={e => setKilometersRan(e.target.value)} />
                </div>

                {Array.from({ length: 3 }, (_, i) => (
                    <div key={i} className="col-lg-4">
                        <p>Upload Bike Photo {i + 1}</p>
                        <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={e => handleFileChange(i, e)}
                            ref={fileInputRefs[i]}
                        />
                    </div>
                ))}

                <div className="col-lg-4 mt-4">
                    <button className="btn btn-primary" onClick={save} disabled={uploading}>
                        {uploading ? "Uploading..." : "Upload Bike"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NewBikeProduct;