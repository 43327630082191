import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs, doc as firestoreDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import swal from "sweetalert";

const BikeOrders = () => {
    const [bikeBookings, setBikeBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const userId = localStorage.getItem("sellerid"); // Assuming sellerId is stored in localStorage

    useEffect(() => {
        const fetchBikeBookings = async () => {
            try {
                setLoading(true);
                const bookingsRef = collection(db, "bookings");
                const q = query(bookingsRef, where("sellerId", "==", userId)); // Query by sellerId
                const querySnapshot = await getDocs(q);

                // Filter out results with bikeName as null and fetch user details
                const bikeBookingsData = await Promise.all(
                    querySnapshot.docs.map(async (bookingDoc) => {
                        const data = bookingDoc.data();
                        if (data.bikeName) { // Ensure it's a bike booking
                            // Fetch user data based on userId in the booking
                            const userDocRef = firestoreDoc(db, "UsersIds", data.userId);
                            const userDocSnap = await getDoc(userDocRef);
                            const userData = userDocSnap.exists() ? userDocSnap.data() : null;

                            return {
                                id: bookingDoc.id,
                                ...data,
                                userName: userData ? userData.Name : "Unknown",
                                userPhone: userData ? userData.phone : "Unknown",
                                bookedAt: data.bookingDate ? data.bookingDate : null,
                            };
                        }
                        return null;
                    })
                );

                // Filter out null values
                setBikeBookings(bikeBookingsData.filter((item) => item !== null));
            } catch (error) {
                console.error("Error fetching bike bookings:", error);
                setError("Could not fetch bike bookings. Please try again.");
                swal("Error", "Could not fetch bike bookings. Please try again.", "error");
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchBikeBookings();
        } else {
            setError("You must be logged in to view your bike bookings.");
            setLoading(false);
        }
    }, [userId]);

    if (loading) {
        return <div className="text-center mt-3">Loading your bike Orders...</div>;
    }

    if (error) {
        return <div className="text-center mt-3">{error}</div>;
    }

    return (
        <div className="container mt-3">
            <h2>My Bike Bookings</h2>
            <div className="row">
                {bikeBookings.length > 0 ? (
                    bikeBookings.map((booking, index) => (
                        <div className="col-lg-3 mb-3" key={`${booking.id}-${index}`}>  {/* Combine id with index for a unique key */}
                            <div className="card shadow-lg">
                                <img
                                    src={booking.bikePhotos && booking.bikePhotos[0]} // Ensure bikePhotos exist
                                    alt={booking.bikeName}
                                    className="img-fluid lazyload"
                                    style={{ width: "100%", height: "200px" }}
                                />
                                <div className="card-body">
                                    <h5 className="card-title">{booking.bikeName}</h5>
                                    <p className="card-text"><strong>Price:</strong> Rs. {booking.bikePrice}</p>
                                    <p className="card-text"><strong>Booking Date:</strong>  {booking.bookedAt ? new Date(booking.bookedAt.seconds * 1000).toLocaleDateString() : "N/A"}</p>
                                    <p className="card-text"><strong>Dealer:</strong> {booking.dealerName}</p>
                                    <p className="card-text"><strong>District:</strong> {booking.distName}</p>
                                    <p className="card-text"><strong>Customer Name:</strong> {booking.userName}</p>
                                    <p className="card-text"><strong>Customer Phone:</strong> {booking.userPhone}</p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-lg-12 text-center">
                        <p>Your bikes have not been booked yet or you may not have posted any bikes yet.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BikeOrders;