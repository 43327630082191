import React, { useState } from "react";
import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import swal from "sweetalert";

const ContactUs = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [reason, setReason] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await addDoc(collection(db, "contactus"), {
                name,
                phone,
                reason,
                timestamp: new Date(),
            });
            swal("Success", "Your message has been sent!", "success");
            setName("");
            setPhone("");
            setReason("");
        } catch (error) {
            swal("Error", "Failed to send your message. Please try again.", "error");
            console.error("Error storing contact us data:", error);
        }
    };

    return (
        <div className="container">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name:</label> <br></br>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        placeholder="Please enter your name"
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone Number:</label> <br></br>
                    <input
                        type="text"
                        id="phone"
                        placeholder="Please enter your phone number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="reason">Reason:</label> <br></br>
                    <textarea
                        id="reason"
                        value={reason}
                        placeholder="Reason for contacting us"
                        onChange={(e) => setReason(e.target.value)}
                        required
                    ></textarea>
                </div>
                <button className="btn btn-primary" type="submit">Submit</button>
            </form>
            <div className="privacy-policy">
                <h3>Privacy Policy</h3>
                <p>
                    We value your privacy. We don't use or sell your data to third parties. 
                    All the information you provide will be kept confidential and used only 
                    to assist with your query or request.
                </p>
            </div>
            <div className="terms-conditions">
                <h3>Terms & Conditions</h3>
                <p>
                    By creating an account or submitting your information, you agree to our 
                    privacy policy and terms of service.
                </p>
            </div>
        </div>
    );
};

export default ContactUs;