import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs, doc as firestoreDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import swal from "sweetalert";
import BikeOrders from "./BikeOrders";

const MyOrder = () => {
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const userId = localStorage.getItem("sellerid"); // Assuming userId is stored in localStorage

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                setLoading(true);
                const bookingsRef = collection(db, "bookings");
                const q = query(bookingsRef, where("sellerId", "==", userId));
                const querySnapshot = await getDocs(q);

                // Filter out bike bookings and process only car bookings
                const bookingsData = await Promise.all(
                    querySnapshot.docs.map(async (bookingDoc) => {
                        const data = bookingDoc.data();

                        // Check if it's a car booking by ensuring carName exists
                        if (data.carName) {
                            // Fetch the user data based on userId in the booking
                            const userDocRef = firestoreDoc(db, "UsersIds", data.userId); // Use 'firestoreDoc' for Firestore 'doc' function
                            const userDocSnap = await getDoc(userDocRef);
                            const userData = userDocSnap.exists() ? userDocSnap.data() : null;

                            return {
                                id: bookingDoc.id,
                                ...data,
                                userName: userData ? userData.Name : "Unknown",
                                userPhone: userData ? userData.phone : "Unknown",
                                bookingDate: data.bookingDate ? data.bookingDate : null, // Ensure bookingDate exists
                            };
                        }
                        return null; // Filter out non-car bookings
                    })
                );

                // Filter out null values from the array
                setBookings(bookingsData.filter((item) => item !== null));
            } catch (error) {
                console.error("Error fetching bookings:", error);
                setError("Could not fetch bookings. Please try again.");
                swal("Error", "Could not fetch bookings. Please try again.", "error");
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchBookings();
        } else {
            setError("You must be logged in to view your bookings.");
            setLoading(false);
        }
    }, [userId]);

    if (loading) {
        return <div className="text-center mt-3">Loading your bookings...</div>;
    }

    if (error) {
        return <div className="text-center mt-3">{error}</div>;
    }

    return (
        <div className="container mt-3">
        <BikeOrders />
        <h2>My Car Orders</h2>
        <div className="row">
            {bookings.length > 0 ? (
                bookings.map((booking, index) => (
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3" key={`${booking.id}-${index}`}>  {/* Adjusted classes */}
                        <div className="card shadow-lg">
                            <img
                                src={booking.carPhotos && booking.carPhotos[0]} // Ensure carPhotos exist
                                alt={booking.carName}
                                className="img-fluid lazyload"
                                style={{ width: "100%", height: "200px" }}
                            />
                            <div className="card-body">
                                <h5 className="card-title">{booking.carName}</h5>
                                <p className="card-text"><strong>Price:</strong> Rs. {booking.carPrice}</p>
                                <p className="card-text"><strong>Booking Date:</strong> {booking.bookingDate ? new Date(booking.bookingDate.seconds * 1000).toLocaleDateString() : "N/A"}</p>
                                <p className="card-text"><strong>Dealer:</strong> {booking.dealerName}</p>
                                <p className="card-text"><strong>District:</strong> {booking.distName}</p>
                                <p className="card-text"><strong>Customer Name:</strong> {booking.userName}</p>
                                <p className="card-text"><strong>Customer Phone:</strong> {booking.userPhone}</p>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="col-lg-12 text-center">
                    <p>You have not booked any cars yet.</p>
                </div>
            )}
        </div>
    </div>
    );
};

export default MyOrder;