import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase'; // Import your Firestore instance
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert'; // Import Alert for success popup
import Slider from "react-slick"; // Import the slider component for image carousel
import './Approve.css';

const ApproveBikePosts = () => {
    const [bikePosts, setBikePosts] = useState([]);
    const [selectedBike, setSelectedBike] = useState(null); // State to manage selected bike for "Know More" modal
    const [loading, setLoading] = useState(true);
    const [showSuccess, setShowSuccess] = useState(false); // State to manage the success message popup

    useEffect(() => {
        const fetchBikePosts = async () => {
            const bikePostsCollection = collection(db, 'bikes');
            const bikePostsSnapshot = await getDocs(bikePostsCollection);
            const bikePostsList = bikePostsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setBikePosts(bikePostsList);
            setLoading(false);
        };

        fetchBikePosts();
    }, []);

    const handleApprove = async (id) => {
        const postDoc = doc(db, 'bikes', id);
        await updateDoc(postDoc, { approved: true });
        setShowSuccess(true); // Show success message
        setTimeout(() => setShowSuccess(false), 2000); // Hide success message after 2 seconds
    };

    const handleDelete = async (id) => {
        const postDoc = doc(db, 'bikes', id);
        await updateDoc(postDoc, { Reject: true });
    };

    const handleCloseModal = () => {
        setSelectedBike(null); // Close the modal
    };

    const handleBikeClick = (bike) => {
        setSelectedBike(bike); // Open the modal with selected bike details
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
    };

    return (
        <div className="container">
            <h2>Approve Bike Posts</h2>

            {/* Success message popup */}
            {showSuccess && (
                <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                    Bike post approved successfully!
                </Alert>
            )}

            <div className="row">
                {bikePosts.length > 0 ? (
                    bikePosts.map((post) => (
                        <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={post.id}>
                            <div className="card1 text-dark bg-warning">
                                <Slider {...sliderSettings}>
                                    {post.bikePhotos.map((photo, index) => (
                                        <div key={index} className="photo-slide">
                                            <img
                                                src={photo}
                                                alt={`Bike ${index + 1}`}
                                                className="img-fluid"
                                            />
                                        </div>
                                    ))}
                                </Slider>
                                <div className="card-body">
                                    <p className="card-text"><strong>{post.bikeName}</strong></p>
                                    <p className="card-text">Rs. {post.bikePrice}, {post.bikeYear} Model</p>
                                    <button className="button2 me-2" onClick={() => handleBikeClick(post)}>Know More</button>
                                    {post.approved ? (
                                        <button className="button1" disabled>Approved</button>
                                    ) : (
                                        <button className="button1" onClick={() => handleApprove(post.id)}>Approve</button>
                                    )}
                                    <button className="button3" onClick={() => handleDelete(post.id)}>Delete</button>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-lg-12 text-center">
                        <p>No bikes to display.</p>
                    </div>
                )}

                {loading && (
                    <div className="col-lg-12 text-center">
                        <p>Loading bikes...</p>
                    </div>
                )}

                {selectedBike && (
                    <Modal show={true} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{selectedBike.bikeName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Slider {...sliderSettings}>
                                {selectedBike.bikePhotos.map((photo, index) => (
                                    <div key={index} className="photo-slide">
                                        <img
                                            src={photo}
                                            alt={`Bike ${index + 1}`}
                                            className="img-fluid"
                                            style={{ width: "100%", height: "250px" }}
                                        />
                                    </div>
                                ))}
                            </Slider>
                            <h5 style={{marginTop:"20px"}}>Price: Rs. {selectedBike.bikePrice}</h5>
                            <p><strong>Dealer:</strong> {selectedBike.dealerName}</p>
                            <p><strong>District:</strong> {selectedBike.distName}</p>
                            <p>Kilometers ran: {selectedBike.kilometersRan} km</p>
                            <p>Year of purchase: {selectedBike.bikeYear}</p>
                            <p>Description: {selectedBike.bikeDescription}</p>
                            <p>Engine Size: {selectedBike.engineSize}</p>
                            <p>Fuel Type: {selectedBike.fuelType}</p>
                            <p>Mileage: {selectedBike.mileage} km</p>
                            <p>Fuel Tank Capacity: {selectedBike.fuelTank} ltr</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default ApproveBikePosts;