import React, { useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Import your Firestore instance
import AdminDashboard from './Approve.js';

const AdminLogin = () => {
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [error, setError] = useState('');

    const handleLogin = async () => {
        const docRef = doc(db, 'adminPasswords', 'rwerwersdfsdgdhdf');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const adminData = docSnap.data();
            if (id === adminData.id && password === adminData.password) {
                setIsLoggedIn(true);
                setError('');
            } else {
                setError('Wrong credentials. Please try again.');
            }
        } else {
            setError('Admin data not found.');
        }
    };

    return (
        <div>
            {isLoggedIn ? (
                <AdminDashboard />
            ) : (
                <div className="login-container">
                    <h2>Admin Login</h2>
                    <input
                        type="text"
                        placeholder="Enter ID"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        className="form-control"
                    />
                    <input
                        type="password"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                    />
                    <button onClick={handleLogin} className="btn btn-primary mt-3">
                        Login
                    </button>
                    {error && <p className="text-danger mt-2">{error}</p>}
                </div>
            )}
        </div>
    );
};

export default AdminLogin;