import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; // Adjust the import path as necessary

const MyProfile = () => {
    const [profileData, setProfileData] = useState(null);

    const currentSellerId = localStorage.getItem("sellerid");
console.log(currentSellerId)
    useEffect(() => {
        const fetchProfileData = async () => {
            if (currentSellerId) {
                try {
                    const docRef = doc(db, "sellerIds", currentSellerId);
                    const docSnap = await getDoc(docRef);
                    console.log(docSnap)

                    if (docSnap.exists()) {
                        setProfileData(docSnap.data());
                    } else {
                        console.error("No such document!");
                    }
                } catch (error) {
                    console.error("Error fetching profile data:", error);
                }
            } else {
                console.error("No seller ID found in local storage.");
            }
        };

        fetchProfileData();
    }, [currentSellerId]);

    if (!profileData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-12 text-center mb-4">
                    <h3 className="text-primary">My Profile</h3>
                </div>
                <div className="col-lg-6">
                    <p><strong>Dealer Name:</strong> {profileData.dealerName}</p>
                </div>
                <div className="col-lg-6">
                    <p><strong>Email:</strong> {profileData.email}</p>
                </div>
                <div className="col-lg-6">
                    <p><strong>Phone:</strong> {profileData.phone}</p>
                </div>
                <div className="col-lg-6">
                    <p><strong>District Name:</strong> {profileData.distName}</p>
                </div>
                <div className="col-lg-6">
                    <p><strong>Address:</strong> {profileData.address}</p>
                </div>
            </div>
        </div>
    );
};

export default MyProfile;