import { useEffect, useState } from "react";
import { collection, query, where, getDocs, limit, startAfter } from "firebase/firestore";
import { db } from "../firebase";
import Slider from "react-slick";
import swal from "sweetalert";
import { useInView } from 'react-intersection-observer';
import './sellerAds.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AdminAddedBikes from "./AddedBikes";

const SellerProducts = () => {
    const [products, setProducts] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [hasMoreProducts, setHasMoreProducts] = useState(true);
    const [productIds, setProductIds] = useState(new Set());

    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    const currentSellerId = localStorage.getItem("sellerid");
    const limitPerPage = 8; // Number of products to load per page

    const fetchProducts = async (lastDoc = null) => {
        try {
            setLoading(true);
            const productsRef = collection(db, "products");
            const q = query(
                productsRef,
                where("sellerId", "==", currentSellerId),
                limit(limitPerPage),
                ...(lastDoc ? [startAfter(lastDoc)] : [])
            );

            const querySnapshot = await getDocs(q);
            const productsData = querySnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(product => !productIds.has(product.id)); // Filter out duplicates

            // Update product IDs set to include newly fetched unique products
            setProductIds(prevIds => new Set([...prevIds, ...productsData.map(product => product.id)]));

            // Check if there are more products to load
            if (productsData.length < limitPerPage) {
                setHasMoreProducts(false);
            }

            // Only update lastVisible if there are more products
            if (querySnapshot.docs.length > 0) {
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            }

            // Avoid adding duplicates
            setProducts(prevProducts => {
                const newProducts = productsData.filter(
                    product => !prevProducts.some(prevProduct => prevProduct.id === product.id)
                );
                return [...prevProducts, ...newProducts];
            });
        } catch (error) {
            swal("Error", "Could not fetch products. Please try again.", "error");
            console.error("Error fetching products:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Clear products list and load initial batch
        setProducts([]);
        setProductIds(new Set());
        setLastVisible(null);
        setHasMoreProducts(true);
        fetchProducts();
    }, [currentSellerId]);

    useEffect(() => {
        if (inView && !loading && hasMoreProducts) {
            fetchProducts(lastVisible);
        }
    }, [inView, lastVisible, loading, hasMoreProducts]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    const handleCloseModal = () => {
        setSelectedProduct(null);
    };

    return (
        <div className="">
            <AdminAddedBikes/>
            <div className="row">
                {products.length > 0 ? (
                    products.map((product, index) => (
                        <div className="col-lg-1 col-md-4 col-sm-6 col-12" style={{minWidth:"180px"}} key={product.id} ref={index === products.length - 1 ? ref : null}>
                            <div className="card4">
                                <Slider {...settings}>
                                    {product.carPhotos.map((photo, index) => (
                                        <div key={index} className="photo-slide">
                                            <img
                                                src={photo}
                                                alt={`Car ${index + 1}`}
                                                className="img-fluid lazyload"
                                                style={{ width: "100%", height: "200px" }}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                                <div className="card-body">
                                    <h4 className="card-text">{product.carName}</h4>
                                    <h5 className="card-text">Rs. {product.carPrice}</h5>
                                    <p className="card-text">{product.carKm} km, {product.carYear} Model</p>
                                    <button className="button2" onClick={() => handleProductClick(product)} style={{ float: "left" }}>Know More</button>
                                    <button className="button1" >Book Now</button>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-lg-12 text-center">
                        <p>No products found.</p>
                    </div>
                )}
            </div>

            {loading && (
                <div className="col-lg-12 text-center">
                    <p>Loading more products...</p>
                </div>
            )}

            {selectedProduct && (
                <Modal show={true} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedProduct.carName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Slider {...settings}>
                            {selectedProduct.carPhotos.map((photo, index) => (
                                <div key={index} className="photo-slide">
                                    <img
                                        src={photo}
                                        alt={`Car ${index + 1}`}
                                        className="img-fluid"
                                        style={{ width: "100%", height: "250px" }}
                                    />
                                </div>
                            ))}
                        </Slider>
                        <h5>Price: Rs. {selectedProduct.carPrice} -only</h5>
                        <p>Kilometers: {selectedProduct.carKm} Km</p>
                        <p>Year: {selectedProduct.carYear}</p>
                        <p>Description: {selectedProduct.carDescription}</p>
                        <p>Engine Size: {selectedProduct.engineSize}</p>
                        <p>Fuel Type: {selectedProduct.fuelType}</p>
                        <p>Ground Clearance: {selectedProduct.groundClearance}</p>
                        <p>Mileage: {selectedProduct.mileage}</p>
                        <p>Safety Rating: {selectedProduct.safetyRating}</p>
                        <p>Seating Capacity: {selectedProduct.seatingCapacity}</p>
                        <p>Size: {selectedProduct.size}</p>
                        <p>Fuel Tank Capacity: {selectedProduct.fuelTank}</p>
                        <p>Transmission: {selectedProduct.transmission}</p>
                        <p>Warranty: {selectedProduct.warranty}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default SellerProducts;