import React from "react";
import img1 from '../pics/fronx-exterior.png';
import img2 from '../pics/i20_Modelpc.png';
import img3 from '../pics/images.png';
import img4 from '../pics/images2.png';

function Carousel() {
    return (
            <div className="row align-items-center text-white bg-dark py-4">
                <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
                    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner" >
                            <div className="carousel-item active">
                                <img src={img1} className="d-block w-100 h-100" alt="Car 1" style={{ objectFit: "cover" }} />
                            </div>
                            <div className="carousel-item">
                                <img src={img3} className="d-block w-100 h-100" alt="Car 2" style={{ objectFit: "cover" }} />
                            </div>
                            <div className="carousel-item">
                                <img src={img2} className="d-block w-100 h-100" alt="Car 3" style={{ objectFit: "cover" }} />
                            </div>
                            <div className="carousel-item">
                                <img src={img4} className="d-block w-100 h-100" alt="Car 4" style={{ objectFit: "cover" }} />
                            </div>
    
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12">
                    <h3 className="text-center text-lg-start">
                        Welcome to Secondhandcar.co!
                    </h3>
                    <p className="text-center text-lg-start">
                        We are a dedicated platform that connects car sellers with potential buyers. Our platform allows sellers to create an account and post advertisements for their used cars. Buyers can browse through a wide range of vehicles, book test drives, or even purchase their dream car with just a few clicks. We aim to make the process of buying and selling used vehicles as seamless and efficient as possible.
                    </p>
                </div>
            </div>
  
    );
}

export default Carousel;