import { useEffect, useState } from "react";
import { collection, query, getDocs, limit, startAfter, addDoc, doc, getDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import Slider from "react-slick";
import swal from "sweetalert";
import { useInView } from 'react-intersection-observer';
import './allPosts.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import HorizontalScrollProducts from "./HorizontalScrollProducts";
import loadingVideo from '../pics/car-loading.mp4';
import CarOffers from "./CarOffers";

const AllPosts = () => {
    const [products, setProducts] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [hasMoreProducts, setHasMoreProducts] = useState(true);
    const [productIds, setProductIds] = useState(new Set());

    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    const navigate = useNavigate();
    const currentUserId = localStorage.getItem("userId");
    const limitPerPage = 8; // Number of products to load per page

    const fetchProducts = async (lastDoc = null) => {
        try {
            setLoading(true);
            const productsRef = collection(db, "products");
            const q = query(
                productsRef,
                limit(limitPerPage),
                ...(lastDoc ? [startAfter(lastDoc)] : []),
                // Add condition to only fetch approved products
                where("approved", "==", true)
            );

            const querySnapshot = await getDocs(q);
            const productsData = querySnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(product => !productIds.has(product.id)); // Filter out duplicates

            // Fetch dealer details
            const updatedProductsData = await Promise.all(
                productsData.map(async (product) => {
                    const sellerDocRef = doc(db, "sellerIds", product.sellerId);
                    const sellerDoc = await getDoc(sellerDocRef);
                    if (sellerDoc.exists()) {
                        const sellerData = sellerDoc.data();
                        return {
                            ...product,
                            dealerName: sellerData.dealerName,
                            distName: sellerData.distName,
                        };
                    }
                    return product;
                })
            );

            setProductIds(prevIds => new Set([...prevIds, ...updatedProductsData.map(product => product.id)]));

            if (updatedProductsData.length < limitPerPage) {
                setHasMoreProducts(false);
            }

            if (querySnapshot.docs.length > 0) {
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            }

            setProducts(prevProducts => {
                const newProducts = updatedProductsData.filter(
                    product => !prevProducts.some(prevProduct => prevProduct.id === product.id)
                );
                return [...prevProducts, ...newProducts];
            });
        } catch (error) {
            swal("Error", "Could not fetch products. Please try again.", "error");
            console.error("Error fetching products:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setProducts([]);
        setProductIds(new Set());
        setLastVisible(null);
        setHasMoreProducts(true);
        fetchProducts();
    }, [currentUserId]);

    useEffect(() => {
        if (inView && !loading && hasMoreProducts) {
            fetchProducts(lastVisible);
        }
    }, [inView, lastVisible, loading, hasMoreProducts]);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        swipeToSlide: true,
    };

    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    const handleBookNow = async (product) => {
        if (!currentUserId) {
            // Redirect to signup if user is not logged in
            swal("Please log in to book this car.");
            navigate('/login');
            return;
        }

        try {
            // Add booking to Firestore
            const bookingData = {
                ...product,
                userId: currentUserId,
                sellerId: product.sellerId,
                bookingDate: new Date(),
            };
            await addDoc(collection(db, "bookings"), bookingData);
            swal("Success", "Car booked successfully!", "success");
        } catch (error) {
            swal("Error", "Could not book the car. Please try again.", "error");
            console.error("Error booking car:", error);
        }
    };

    const handleCloseModal = () => {
        setSelectedProduct(null);
    };

    return (
        <div className="bg-light-subtle">
            <div className="" style={{marginTop:"10px",paddingBottom:"30px"}}>
                <HorizontalScrollProducts products={products} />
            </div>
             <div>
                <CarOffers products={products} /> {/* Pass products to CarOffers as cars */}
            </div>
            <div className="row">
                {products.length > 0 ? (
                    products.map((product, index) => (
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12" key={product.id} ref={index === products.length - 1 ? ref : null}>
                            <div className="card2">
                                <Slider {...settings}>
                                    {product.carPhotos.map((photo, index) => (
                                        <div key={index} className="photo-slide">
                                            <img
                                                src={photo}
                                                alt={`Car ${index + 1}`}
                                                className="img-fluid"
                                            />
                                        </div>
                                    ))}
                                </Slider>
                                <div className="card-body">
                                    <p className="card-text"><strong>{product.carName}</strong></p>
                                    <p className="card-text">Rs.{product.carPrice}, {product.carYear} Model</p>
                                    <p className="card-text">Dealer: {product.dealerName}</p>
                                    <button className="button2" onClick={() => handleProductClick(product)} style={{ float: "left" }}>Know More</button>
                                    <button className="button1" onClick={() => handleBookNow(product)}>Book Now</button>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="loading-screen">
                    <video src={loadingVideo} autoPlay loop muted style={{ width: '300px', height: '500px' }} />
                </div>
                )}
            </div>

            {loading && (
                        <div className="loading-screen">
                        <video src={loadingVideo} autoPlay loop muted style={{width: '300px', height: '500px' }} />
                    </div>
            )}

            {selectedProduct && (
                <Modal show={true} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedProduct.carName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Slider {...settings}>
                            {selectedProduct.carPhotos.map((photo, index) => (
                                <div key={index} className="photo-slide" >
                                    <img
                                        src={photo}
                                        alt={`Car ${index + 1}`}
                                        className="img-fluid"
                                        style={{ width: "100%", height: "250px" }}
                                    />
                                </div>
                            ))}
                        </Slider>
                        <h5 style={{ marginTop: "25px" }}><strong>Dealer:</strong> {selectedProduct.dealerName}</h5>
                        <h5>Price: Rs. {selectedProduct.carPrice}</h5>
                        <p><strong>District:</strong> {selectedProduct.distName}</p>
                        <p>Kilometers: {selectedProduct.carKm} km</p>
                        <p>Year of purchase: {selectedProduct.carYear}</p>
                        <p>Description: {selectedProduct.carDescription}</p>
                        <p>Engine Size: {selectedProduct.engineSize}</p>
                        <p>Fuel Type: {selectedProduct.fuelType}</p>
                        <p>Ground Clearance: {selectedProduct.groundClearance}</p>
                        <p>Mileage: {selectedProduct.mileage} km</p>
                        <p>Safety Rating: {selectedProduct.safetyRating} star</p>
                        <p>Seating Capacity: {selectedProduct.seatingCapacity} seater</p>
                        <p>Fuel Tank Capacity: {selectedProduct.fuelTank} ltr</p>
                        <p>Transmission: {selectedProduct.transmission}</p>
                        <p>Warranty: {selectedProduct.warranty} years</p>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button style={{ margin: "5px" }} variant="success" onClick={() => handleBookNow(selectedProduct)}>
                            Book Now
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default AllPosts;