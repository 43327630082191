import { useEffect, useState } from "react";
import { HashRouter, Routes, Route, Link } from "react-router-dom";
import Mylogin from './login';
import AdminSignUp from "../admin/AdminSignup";
import Adminlogin from "../admin/Adminlogin";
import UserSignUp from "./UserSignUp";
import MyBookings from "./MyBookins";
import './userapp.css';
import logo from '../logo.png';
import TabsComponent from "./Tabs";
import ContactUs from "./privacyPolicy";
import AdminLogin from "./siteAdminDashboard";
import SellerPostsOnIds from "./SellerPostsOnIds";
import SellersProfiles from "./SellersProfiles";

const RotatingText = () => {
    const texts = ["Car", "Bike", "SUV", "XUV", "VAN"];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % texts.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [texts.length]);

    return (
        <div className="rotating-text">
            <div className="text">{texts[currentIndex]}</div>
        </div>
    );
};

const UserApp = () => {
    const isLoggedIn = localStorage.getItem("userName") !== null;

    const handleLogout = () => {
        localStorage.removeItem("userName");
        localStorage.removeItem("UserPhone");
        window.location.reload();
        window.location.href = ('/')
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const navbarToggler = document.querySelector('.navbar-collapse');
            const navbarToggleButton = document.querySelector('.navbar-toggler');

            if (
                navbarToggler.classList.contains('show') &&
                !navbarToggler.contains(event.target) &&
                !navbarToggleButton.contains(event.target)
            ) {
                navbarToggler.classList.remove('show');
            }
        };

        document.addEventListener('click', handleClickOutside);
        window.addEventListener('scroll', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
            window.removeEventListener('scroll', handleClickOutside);
        };
    }, []);

    return (
        <HashRouter>
            <nav className="navbar navbar-expand-sm navbar-dark bg-dark p-1 sticky-top">
                <div className="container">
                    <div className="navbar-brand">
                        <img src={logo} style={{ width: "60px", height: "45px" }} alt="Logo" />Secondhand<RotatingText />
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="mynavbar">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item me-4">
                                <Link className="nav-link active" to="/"> <i className="fa fa-home"></i> Home </Link>
                            </li>
                            {isLoggedIn && (
                                <li className="nav-item me-4">
                                    <Link className="nav-link active" to="/MyBookings"> <i className="fa fa-shopping-cart"></i> My Bookings </Link>
                                </li>
                            )}
                            <li className="nav-item dropdown me-4">
                                <div className="nav-link dropdown-toggle active" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-user"></i> User
                                </div>
                                <ul className="dropdown-menu" aria-labelledby="userDropdown">
                                    {isLoggedIn ? (
                                        <li>
                                            <button className="dropdown-item" onClick={handleLogout}>
                                                Logout
                                            </button>
                                        </li>
                                    ) : (
                                        <>
                                            <li><Link className="dropdown-item" to="/login">User Login</Link></li>
                                            <li><Link className="dropdown-item" to="/UserSignUp">User Sign Up</Link></li>
                                        </>
                                    )}
                                </ul>
                            </li>
                            <li className="nav-item dropdown me-4">
                                <div className="nav-link dropdown-toggle active" id="sellerDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-user-tie"></i> Seller
                                </div>
                                <ul className="dropdown-menu" aria-labelledby="sellerDropdown">
                                    <li><Link className="dropdown-item" to="/slogin">Seller Login</Link></li>
                                    <li><Link className="dropdown-item" to="/sSignup">Seller Sign Up</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item me-4">
                                <Link className="nav-link active" to="/ContactUs"> <i className=""></i> Contact Us </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <Routes>
                <Route exact path="/" element={<TabsComponent />} />
                <Route exact path="/login" element={<Mylogin />} />
                <Route exact path="/slogin" element={<Adminlogin />} />
                <Route exact path="/UserSignUp" element={<UserSignUp />} />
                <Route exact path="/sSignup" element={<AdminSignUp />} />
                <Route exact path="/AdminLogin" element={<AdminLogin />} />
                <Route exact path="/ContactUs" element={<ContactUs />} />

                {isLoggedIn && <Route exact path="/MyBookings" element={<MyBookings />} />}
                <Route path="/dealers/:sellerId" element={<SellerPostsOnIds />} />
                <Route path="/SellersProfiles" element={<SellersProfiles />} />
            </Routes>

            <footer className="bg-mycolor text-white p-5 mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h4>About Us</h4>
                            <p>
                                Welcome to Secondhandcar.co! We are a dedicated platform that connects car sellers with potential buyers. Our platform allows sellers to create an account and post advertisements for their used cars. Buyers can browse through a wide range of vehicles, book test drives with just a few clicks. We aim to make the process of buying and selling used vehicles as seamless and efficient as possible.
                            </p>
                            <p>
                                Whether you're looking to sell your car or purchase a pre-owned vehicle, Secondhandcar provides a reliable and secure environment to meet your needs. Join our community today and discover how easy and hassle-free it can be to buy or sell a car!
                            </p>
                        </div>
                        <div className="col-lg-4">
                            <h4>Contact Support</h4>
                            <p>Mobile: +91-9365919522</p>
                            <p>Email: sarifkhanngn02@gmail.com</p>
                        </div>
                        <div className="col-lg-4">
                            <h4>Follow Us on Social Media</h4>
                            <p><i className="fab fa-facebook fa-lg"></i> www.facebook.com</p>
                            <p><i className="fab fa-twitter fa-lg"></i> www.twitter.com</p>
                            <p><i className="fab fa-linkedin fa-lg"></i> www.linkedin.com</p>
                        </div>
                    </div>
                </div>
            </footer>
        </HashRouter>
    );
};

export default UserApp;