import { useState } from "react";
import swal from "sweetalert";
import { db } from "../firebase"; // Import the Firebase Firestore instance
import { collection, query, where, getDocs } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for programmatic navigation

const Mylogin = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    let [email, pickEmail] = useState("");
    let [password, pickPassword] = useState("");

    const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const validatePassword = (password) => {
        return password.length >= 6;
    }

    const gologin = async () => {
        if (!validateEmail(email)) {
            swal("Invalid Input", "Please enter a valid e-Mail address", "warning");
        } else if (!validatePassword(password)) {
            swal("Invalid Input", "Password should be at least 6 characters long", "warning");
        } else {
            try {
                const q = query(collection(db, "UsersIds"), where("email", "==", email.trim()), where("password", "==", password.trim()));
                const querySnapshot = await getDocs(q);
    
                if (!querySnapshot.empty) {
                    const userInfo = querySnapshot.docs[0].data();
                    localStorage.setItem("userId", querySnapshot.docs[0].id);
                    localStorage.setItem("userName", userInfo.Name); // Make sure the field is `Name`
                    swal("Success", "Login Successful", "success", { timer: 1500 }).then(() => {
                    navigate('/');  // Use navigate to redirect after successful login
                    window.location.reload();
                    });
                   
                } else {
                    swal("Invalid", "Email or Password is incorrect", "warning");
                }
            } catch (error) {
                console.error("Error during login: ", error);
                swal("Error", "Could not login", "error");
            }
        }
    }

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                    <div className="card border-0 shadow-lg">
                        <div className="card-header bg-primary text-white">Login</div>
                        <div className="card-body">
                            <div className="mb-3">
                                <label>e-Mail Id</label>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    className="form-control"
                                    onChange={obj => pickEmail(obj.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label>Password</label>
                                <input
                                    type="password"
                                    placeholder="Enter your password"
                                    className="form-control"
                                    onChange={obj => pickPassword(obj.target.value)}
                                />
                            </div>
                            {/* <div className="text-center">
                                <Link to="/ForgotPassword" className="text-primary">
                                    Forgot Password?
                                </Link>
                            </div> */}
                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-danger mb-3" onClick={gologin}>
                                Login <i className="fa fa-arrow-right"></i>
                            </button>
                            <div>
                                <Link to="/UserSignUp">
                                    <b>
                                        Create New Account
                                    </b>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4"></div>
            </div>
        </div>
    )
}

export default Mylogin;